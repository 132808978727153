exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-a-demo-enterprise-js": () => import("./../../../src/pages/book-a-demo-enterprise.js" /* webpackChunkName: "component---src-pages-book-a-demo-enterprise-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-book-a-demo-team-js": () => import("./../../../src/pages/book-a-demo-team.js" /* webpackChunkName: "component---src-pages-book-a-demo-team-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-book-a-demo-enterprise-js": () => import("./../../../src/pages/en/book-a-demo-enterprise.js" /* webpackChunkName: "component---src-pages-en-book-a-demo-enterprise-js" */),
  "component---src-pages-en-book-a-demo-team-js": () => import("./../../../src/pages/en/book-a-demo-team.js" /* webpackChunkName: "component---src-pages-en-book-a-demo-team-js" */),
  "component---src-pages-en-cookie-policy-js": () => import("./../../../src/pages/en/cookie-policy.js" /* webpackChunkName: "component---src-pages-en-cookie-policy-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-learn-copilot-js": () => import("./../../../src/pages/en/learn/copilot.js" /* webpackChunkName: "component---src-pages-en-learn-copilot-js" */),
  "component---src-pages-en-learn-excel-js": () => import("./../../../src/pages/en/learn/excel.js" /* webpackChunkName: "component---src-pages-en-learn-excel-js" */),
  "component---src-pages-en-learn-outlook-js": () => import("./../../../src/pages/en/learn/outlook.js" /* webpackChunkName: "component---src-pages-en-learn-outlook-js" */),
  "component---src-pages-en-learn-powerbi-js": () => import("./../../../src/pages/en/learn/powerbi.js" /* webpackChunkName: "component---src-pages-en-learn-powerbi-js" */),
  "component---src-pages-en-learn-powerpoint-js": () => import("./../../../src/pages/en/learn/powerpoint.js" /* webpackChunkName: "component---src-pages-en-learn-powerpoint-js" */),
  "component---src-pages-en-learn-teams-js": () => import("./../../../src/pages/en/learn/teams.js" /* webpackChunkName: "component---src-pages-en-learn-teams-js" */),
  "component---src-pages-en-learn-vba-js": () => import("./../../../src/pages/en/learn/vba.js" /* webpackChunkName: "component---src-pages-en-learn-vba-js" */),
  "component---src-pages-en-pricing-js": () => import("./../../../src/pages/en/pricing.js" /* webpackChunkName: "component---src-pages-en-pricing-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-whitepaper-js": () => import("./../../../src/pages/en/whitepaper.js" /* webpackChunkName: "component---src-pages-en-whitepaper-js" */),
  "component---src-pages-en-why-5-miles-js": () => import("./../../../src/pages/en/why-5miles.js" /* webpackChunkName: "component---src-pages-en-why-5-miles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-copilot-js": () => import("./../../../src/pages/learn/copilot.js" /* webpackChunkName: "component---src-pages-learn-copilot-js" */),
  "component---src-pages-learn-excel-js": () => import("./../../../src/pages/learn/excel.js" /* webpackChunkName: "component---src-pages-learn-excel-js" */),
  "component---src-pages-learn-outlook-js": () => import("./../../../src/pages/learn/outlook.js" /* webpackChunkName: "component---src-pages-learn-outlook-js" */),
  "component---src-pages-learn-powerbi-js": () => import("./../../../src/pages/learn/powerbi.js" /* webpackChunkName: "component---src-pages-learn-powerbi-js" */),
  "component---src-pages-learn-powerpoint-js": () => import("./../../../src/pages/learn/powerpoint.js" /* webpackChunkName: "component---src-pages-learn-powerpoint-js" */),
  "component---src-pages-learn-teams-js": () => import("./../../../src/pages/learn/teams.js" /* webpackChunkName: "component---src-pages-learn-teams-js" */),
  "component---src-pages-learn-vba-js": () => import("./../../../src/pages/learn/vba.js" /* webpackChunkName: "component---src-pages-learn-vba-js" */),
  "component---src-pages-nl-404-js": () => import("./../../../src/pages/nl/404.js" /* webpackChunkName: "component---src-pages-nl-404-js" */),
  "component---src-pages-nl-book-a-demo-enterprise-js": () => import("./../../../src/pages/nl/book-a-demo-enterprise.js" /* webpackChunkName: "component---src-pages-nl-book-a-demo-enterprise-js" */),
  "component---src-pages-nl-book-a-demo-js": () => import("./../../../src/pages/nl/book-a-demo.js" /* webpackChunkName: "component---src-pages-nl-book-a-demo-js" */),
  "component---src-pages-nl-book-a-demo-team-js": () => import("./../../../src/pages/nl/book-a-demo-team.js" /* webpackChunkName: "component---src-pages-nl-book-a-demo-team-js" */),
  "component---src-pages-nl-cookie-policy-js": () => import("./../../../src/pages/nl/cookie-policy.js" /* webpackChunkName: "component---src-pages-nl-cookie-policy-js" */),
  "component---src-pages-nl-index-js": () => import("./../../../src/pages/nl/index.js" /* webpackChunkName: "component---src-pages-nl-index-js" */),
  "component---src-pages-nl-learn-copilot-js": () => import("./../../../src/pages/nl/learn/copilot.js" /* webpackChunkName: "component---src-pages-nl-learn-copilot-js" */),
  "component---src-pages-nl-learn-excel-js": () => import("./../../../src/pages/nl/learn/excel.js" /* webpackChunkName: "component---src-pages-nl-learn-excel-js" */),
  "component---src-pages-nl-learn-outlook-js": () => import("./../../../src/pages/nl/learn/outlook.js" /* webpackChunkName: "component---src-pages-nl-learn-outlook-js" */),
  "component---src-pages-nl-learn-powerbi-js": () => import("./../../../src/pages/nl/learn/powerbi.js" /* webpackChunkName: "component---src-pages-nl-learn-powerbi-js" */),
  "component---src-pages-nl-learn-powerpoint-js": () => import("./../../../src/pages/nl/learn/powerpoint.js" /* webpackChunkName: "component---src-pages-nl-learn-powerpoint-js" */),
  "component---src-pages-nl-learn-teams-js": () => import("./../../../src/pages/nl/learn/teams.js" /* webpackChunkName: "component---src-pages-nl-learn-teams-js" */),
  "component---src-pages-nl-learn-vba-js": () => import("./../../../src/pages/nl/learn/vba.js" /* webpackChunkName: "component---src-pages-nl-learn-vba-js" */),
  "component---src-pages-nl-pricing-js": () => import("./../../../src/pages/nl/pricing.js" /* webpackChunkName: "component---src-pages-nl-pricing-js" */),
  "component---src-pages-nl-privacy-policy-js": () => import("./../../../src/pages/nl/privacy-policy.js" /* webpackChunkName: "component---src-pages-nl-privacy-policy-js" */),
  "component---src-pages-nl-whitepaper-js": () => import("./../../../src/pages/nl/whitepaper.js" /* webpackChunkName: "component---src-pages-nl-whitepaper-js" */),
  "component---src-pages-nl-why-5-miles-js": () => import("./../../../src/pages/nl/why-5miles.js" /* webpackChunkName: "component---src-pages-nl-why-5-miles-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-whitepaper-js": () => import("./../../../src/pages/whitepaper.js" /* webpackChunkName: "component---src-pages-whitepaper-js" */),
  "component---src-pages-why-5-miles-js": () => import("./../../../src/pages/why-5miles.js" /* webpackChunkName: "component---src-pages-why-5-miles-js" */)
}

