import React from 'react';
import { useTranslation } from 'react-i18next';

const PageContext = React.createContext({});

export const PageContextProvider = ({ children, pageContext }) => {
  const { i18n } = useTranslation();

  if (i18n.language !== pageContext.lang) {
    i18n.changeLanguage(pageContext.lang);
  }

  return (
    <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
  );
};

export const usePageContext = () => React.useContext(PageContext);
