/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

import React from 'react';
import { I18nextProvider } from 'react-i18next';

import { PageContextProvider } from './src/components/PageContext';
import i18n from './src/utils/i18n';

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => (
  <I18nextProvider i18n={i18n}>{element}</I18nextProvider>
);

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({ element, props }) => (
  <PageContextProvider pageContext={props.pageContext}>
    {element}
  </PageContextProvider>
);
